import clsx from 'clsx';
import { Col, Container } from 'react-bootstrap';
import { FaHiking } from 'react-icons/fa';
import styles from './LoyaltyRewards.module.scss';

export const skyhookClubTiers = [
  {
    title: 'Bronze',
    subtitle: 'No completed trips',
    description: 'New to Skyhook. Let the adventures begin!',
    reward: 'New to Skyhook',
    color: '#cd7f32',
    nextTier: 'Silver',
  },
  {
    title: 'Silver',
    subtitle: 'Completed 1 Skyhook trip',
    description: 'Earn £30 credit towards your next trip',
    reward: '£30 credit towards your next trip',
    color: '#71706e',
    nextTier: 'Gold',
  },
  {
    title: 'Gold',
    subtitle: 'Completed 2 Skyhook trips',
    description: 'Earn £40 credit towards your next trip',
    reward: '£40 credit towards your next trip',
    color: '#d4af37',
    nextTier: 'Platinum',
  },
  {
    title: 'Platinum',
    subtitle: 'Completed 3 Skyhook trips',
    description: 'Earn £50 credit towards your next trip',
    reward: '£50 credit towards your next trip',
    color: '#a0b2c6',
    nextTier: 'Diamond',
  },
  {
    title: 'Diamond',
    subtitle: 'Completed 4+ Skyhook trips',
    description: 'Earn £60 credit towards your next trip',
    reward: '£60 credit towards your next trip',
    color: '#182ce8',
  },
];

type LoyaltyRewardsEntryProps = {
  tier: (typeof skyhookClubTiers)[number];
};

export function LoyaltyRewardsEntry({ tier }: LoyaltyRewardsEntryProps) {
  return (
    <div key={tier.title}>
      <FaHiking
        size={40}
        className="mb-3"
        style={{
          fill: tier.color,
        }}
      />

      <h5 className="text-dark">{tier.title}</h5>
      <p className="py-1 mb-2 fst-italic">{tier.subtitle}</p>
      <p>{tier.description}</p>
    </div>
  );
}

type LoyaltyRewardsProps = {
  isCustomerDashboard?: boolean;
};

export default function LoyaltyRewards({
  isCustomerDashboard = false,
}: LoyaltyRewardsProps) {
  return (
    <section
      className={clsx(
        'position-relative mt-4',
        isCustomerDashboard ? undefined : ' bg-light',
      )}
    >
      <Container className="py-5 mb-5">
        <Col xs={12} lg={7} className="text-center mx-auto py-2">
          <h2 className="fw-bold">
            {isCustomerDashboard ? 'How it works' : 'Loyalty Rewards'}
          </h2>
          <p className="">Earn credits each time you travel with us.</p>
        </Col>
        <Col xs={12} className="text-center mx-auto py-5">
          <div className={styles.tiles}>
            {skyhookClubTiers.map((tier) => (
              <LoyaltyRewardsEntry tier={tier} key={tier.title} />
            ))}
          </div>
        </Col>
      </Container>
    </section>
  );
}
