import { useQuery } from '@tanstack/react-query';
import { Discount } from '@website/pages/me/[userID]/discounts';
import clsx from 'clsx';
import { Col, Container } from 'react-bootstrap';
import ReviewsSwiper from '../about/ReviewsSwiper';
import { DiscountCard } from '../discounts/DiscountCard';

export default function PartnerDiscounts() {
  const { isLoading, data, error } = useQuery<Discount[]>({
    queryKey: ['discounts', 'skyhook-club'],
    queryFn: async () => {
      const res = await fetch('/api/contentful/partner-discounts');
      return res.json();
    },
  });

  if (isLoading || error || !data || data.length === 0) {
    return null;
  }

  return (
    <section className={clsx('position-relative mt-4', 'bg-light')}>
      <Container className="py-5">
        <Col xs={12} lg={7} className="text-center mx-auto py-2">
          <h2 className="fw-bold">Partner Discounts</h2>
          <p className="">
            Gain access to discounts on travel related products and services
            from companies we've partnered with, including...
          </p>
        </Col>

        <ReviewsSwiper
          data={data}
          reviewComponent={({ data: discount }) => (
            <Col className="w-100 h-100">
              <DiscountCard discount={discount} />
            </Col>
          )}
        />
      </Container>
    </section>
  );
}
