import { Discount } from '@website/pages/me/[userID]/discounts';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FaCheckCircle, FaCopy } from 'react-icons/fa';
import Imgix from 'react-imgix';
import { getImageUrl } from '../media/utils/imgix';

export function DiscountCard({ discount }: { discount: Discount }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyDiscountCode = () => {
    navigator.clipboard.writeText(discount.discountCode);
    setIsCopied(true);
  };

  return (
    <Card className="border-0 h-100 shadow">
      <Imgix
        src={`${getImageUrl(
          discount.logo.url,
          process.env.NEXT_PUBLIC_CONTENTFUL_IMGIX_URL,
        )}`}
        imgixParams={{
          auto: 'compress,format,enhance,redeye',
          crop: 'faces',
          fit: 'crop',
          ar: '16:9',
        }}
        htmlAttributes={{
          style: {
            aspectRatio: '16/9',
          },
          draggable: false,
        }}
        sizes="(min-width: 1400px) 416px, (min-width: 1200px) 356px, (min-width: 992px) 296px, (min-width: 768px) 223px, (min-width: 576px) 246px, 95vw"
        className="img-fluid card-img-top"
      />
      <Card.Body>
        <Card.Title
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
          }}
        >
          {discount.name}
        </Card.Title>
        <Card.Text
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            wordWrap: 'break-word',
          }}
        >
          {discount.description}
        </Card.Text>
        {discount.discountCode && (
          <Card.Text>
            <p className="p-0 mb-0">
              <span>Discount Code:</span>
              <span className="fw-bold ms-1">{discount.discountCode}</span>
            </p>
            <Button
              variant={isCopied ? 'outline-success' : 'outline-dark'}
              size="sm"
              className="block"
              onClick={handleCopyDiscountCode}
            >
              {isCopied ? (
                <>
                  <FaCheckCircle /> Copied
                </>
              ) : (
                <>
                  <FaCopy /> Copy{' '}
                </>
              )}
            </Button>
          </Card.Text>
        )}{' '}
      </Card.Body>
      {discount.url && (
        <Card.Footer className="bg-white border-0 text-center">
          <Button
            target="_blank"
            href={discount.url}
            rel="noreferrer noopener"
            className="w-100"
            variant="primary"
          >
            Visit Website
          </Button>{' '}
        </Card.Footer>
      )}
    </Card>
  );
}
